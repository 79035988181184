import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { accountService, alertService, billService } from "../../_services";
import Currency from "react-currency-formatter";
import { filter } from "rxjs/operators";
import { ResetPassword } from "../../account/ResetPassword";

import { Members } from "./Members";
import { Roles } from "./Roles";
import "./View.css";
import Invoice from "./Invoice";

function View({ history, match }) {

  const { id } = match.params;
  const [user, setUser] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalComponent, setModalComponent] = useState(null);
  const [isDeactivated, setIsDeactivated] = useState(user&& user.status === 'deactivated');
  const [showConfirm, setShowConfirm] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [invoiceTitle, setInvoiceTitle] = useState("");
  const [invoiceComponent, setInvoiceComponent] = useState(null);
  let loggedInUser = JSON.parse(sessionStorage.getItem('userData'));
  const MemberIdCheck = (id) => /^[a-f0-9]{24}$/i.test(id);

  const [bill, setBill] = useState({
    purchaseItems: [
      {
        price_data: {
          currency: "INR",
          unit_amount: 0,
          product_data: {
            name: "",
            description: "",
          },
        },
        quantity: 1,
      },
    ],
  });

  const [isSubmitting, setSubmitting] = useState(false);

  /// Edited By rabindra
  const [billData, setBillData] = useState();
  const [editBill, setEditBill] = useState(false);
  const [createBill, setCreateBill] = useState(false);
  const [createBillData, setCreateBillData] = useState({
    payStatus: "paid",
    purchaseItems: [
      {
        price_data: {
          currency: "INR",
          unit_amount: 0,
          product_data: {
            name: "",
            description: "",
          },
        },
        quantity: 1,
      },
    ],
  });
  const [checkBox, setCheckBox] = useState("payed");
  const [role, setRole] = useState({});
  const [editUserDetails, setEditUserDetails] = useState();

  useEffect(() => {
    // get user and set form fields
    accountService.getById(id).then((user) => {
      // console.log(user);
      billService.getUserBill(user.id).then((bills) => {
        if (bills && bills.length) {
          bills[0].purchaseItems = JSON.parse(bills[0].purchaseItems);
          setBill(bills[0]);
        }
      });
      setUser(user);

      billService.getUserBills(id).then((bills) => {
        if (bills && bills.length) {
          setBillData(bills);
          // console.log(bills);
        }
      });

      // console.log("user");
      // console.log(billData);
    });

    const subscription = accountService.user.subscribe((x) => setRole(x));
  }, [isSubmitting]);

  const updateBill = (e, property) => {
    let newBill = bill;
    newBill[property] = e.target.value;
    setBill(newBill);
  };

  const updateBillProduct = (e, field) => {
    let newBill = bill;
    newBill["purchaseItems"][0]["price_data"]["product_data"][field] =
      e.target.value;
    setBill(newBill);
  };

  const createBillProduct = (e, field) => {
    let newBill = createBillData;
    newBill["purchaseItems"][0]["price_data"]["product_data"][field] =
      e.target.value;
    setCreateBillData(newBill);
  };

  const updateBillPrice = (e, field) => {
    let newBill = bill;
    newBill["purchaseItems"][0]["price_data"][field] = e.target.value;
    setBill(newBill);
  };

  const createBillPrice = (e, field) => {
    let newBill = createBillData;
    newBill["purchaseItems"][0]["price_data"][field] = e.target.value;
    setCreateBillData(newBill);
  };

  const updateBillPayStatus = (e, field) => {
    let newBill = bill;
    newBill[field] = e.target.value;
    setBill(newBill);
  };

  const createBillPayStatus = (e, field) => {
    let newBill = createBillData;
    // console.log(e.target.value);
    newBill[field] = e.target.value;
    setCreateBillData(newBill);
  };

  function onBillingInfoUpdate(id) {
    setSubmitting(true);
    let billSummary = {};
    billSummary.purchaseItems = [
      {
        price_data: {
          currency: bill.purchaseItems[0].price_data.currency,
          unit_amount: bill.purchaseItems[0].price_data.unit_amount.toString(),
          product_data: {
            name: bill.purchaseItems[0].price_data.product_data.name,
            description:
              bill.purchaseItems[0].price_data.product_data.description,
          },
        },
        quantity: 1,
      },
    ];
    billSummary.customer_email = user.email;
    billSummary.success_url = "https://cms.digitallive24.com";
    billSummary.cancel_url = "https://cms.digitallive24.com";
    billSummary.userId = user.id;
    billSummary.appName = "Signage-CMS";
    billSummary.stripeCustomer = "";
    billSummary.payType = "onetime";
    billSummary.payStatus = bill.payStatus; //TODO: make a state to mark payed on unpaid
    billSummary.invoicePath = "";
    billSummary.receiptPath = "";


    billService
      .updateBill(id, billSummary)
      .then((data) => {
        // console.log(data);
        alertService.success("Bill Updated Successfully", {
          keepAfterRouteChange: true,
        });
        setSubmitting(false); // submit animation stoped
        setEditBill(false); // Edit form is closing
        // history.push('.');
      })
      .catch((error) => {
        setSubmitting(false);
        alertService.error(error);
      });
  }

  const handleToggleAccountStatus = () => {
    const newStatus = isDeactivated ? 'demo' : 'deactivated';
    const updatedUser = { ...user, status: newStatus }; // Toggle the status

    accountService.update(user.id, updatedUser)
      .then(() => {
        alertService.success(`Account ${newStatus === 'deactivated' ? 'deactivated' : 'activated'} successfully`);
        setIsDeactivated(newStatus === 'deactivated'); // Update local state
      })
      .catch((error) => {
        alertService.error(error);
      });
  };

  const handleConfirm = () => {
    setShowConfirm(false); // Close the modal
    handleToggleAccountStatus(); // Proceed with status update
  };


  function onCreateBill() {
    setSubmitting(true);
    let billSummary = {};
    billSummary.purchaseItems = [
      {
        price_data: {
          currency: createBillData.purchaseItems[0].price_data.currency,
          unit_amount:
            createBillData.purchaseItems[0].price_data.unit_amount.toString(),
          product_data: {
            name: createBillData.purchaseItems[0].price_data.product_data.name,
            description:
              createBillData.purchaseItems[0].price_data.product_data
                .description,
          },
        },
        quantity: 1,
      },
    ];
    billSummary.customer_email = user.email;
    billSummary.success_url = "https://cms.digitallive24.com";
    billSummary.cancel_url = "https://cms.digitallive24.com";
    billSummary.userId = user.id;
    billSummary.appName = "Signage-CMS";
    billSummary.stripeCustomer = "";
    billSummary.payType = "onetime";
    billSummary.payStatus = createBillData.payStatus;
    billSummary.invoicePath = "";
    billSummary.receiptPath = "";

    // console.log(billSummary);
    billService
      .createBill(billSummary)
      .then((data) => {
        // console.log(data);
        alertService.success("Bill Created Successfully", {
          keepAfterRouteChange: true,
        });
        setSubmitting(false); // submit animation stoped
        setCreateBill(false); // Edit form is closing
        // history.push('.');
      })
      .catch((error) => {
        setSubmitting(false);
        setCreateBill(false);
        alertService.error(error);
      });
  }

  function convertDateFormate(dateString) {
    const date = new Date(dateString);
    const month = date.getMonth() + 1;
    const dateNumber = date.getDate();
    const year = date.getFullYear();
    const formattedDate = `${month}/${dateNumber}/${year}`;
    return formattedDate;
  }

  const editBillData = async (fid) => {
    const data = await billService.getUserBills(id).then((bills) => {
      if (bills && bills.length) {
        return bills.filter((field) => {
          return field._id === fid;
        });
      }
    });
    data[0].purchaseItems = JSON.parse(data[0].purchaseItems);
    setBill(data[0]);
    // console.log(data[0]);
    setEditBill(true);
  };

  const openPayment = () => {
    return true;
  }

  const updateSetCheckBox = async (bid, status) => {
    setSubmitting(true);

    const data = await billService.getUserBills(id).then((bills) => {
      if (bills && bills.length) {
        return bills.filter((field) => {
          return field._id === bid;
        });
      }
    });

    data[0].purchaseItems = JSON.parse(data[0].purchaseItems);
    setBill(data[0]);



    let billSummary = {};
    billSummary.purchaseItems = [
      {
        price_data: {
          currency: data[0].purchaseItems[0].price_data.currency,
          unit_amount:
            data[0].purchaseItems[0].price_data.unit_amount.toString(),
          product_data: {
            name: data[0].purchaseItems[0].price_data.product_data.name,
            description:
              data[0].purchaseItems[0].price_data.product_data.description,
          },
        },
        quantity: 1,
      },
    ];
    billSummary.customer_email = user.email;
    billSummary.success_url = "https://cms.digitallive24.com";
    billSummary.cancel_url = "https://cms.digitallive24.com";
    billSummary.userId = user.id;
    billSummary.appName = "Signage-CMS";
    billSummary.stripeCustomer = "";
    billSummary.payType = "onetime";
    billSummary.payStatus = status;
    billSummary.invoicePath = "";
    billSummary.receiptPath = "";


    billService
      .updateBill(bid, billSummary)
      .then((data) => {
        // console.log(data);
        alertService.success("Bill Updated Successfully", {
          keepAfterRouteChange: true,
        });
        setSubmitting(false); // submit animation stoped
        setEditBill(false); // Edit form is closing
        // history.push('.');
      })
      .catch((error) => {
        setSubmitting(false);
        alertService.error(error);
      });
  };
let userDetails =user;
  const UserDetails = ({ title, value }) => {
    const [field, setField] = useState(value);
    const [editfield, setEditField] = useState(false);

    const handleUpdate = async (e) => {
      e.preventDefault();
      setSubmitting(true);
      const newUser = { ...user };
      newUser[title] = field;
      try {
        await accountService.update(user.id, newUser);
        setEditField(false);
        setSubmitting(false);
      } catch (error) {
        console.error("Failed to update user details", error);
      }
    };

    return (
      <li className="user-details-item d-flex col-md-6 col-12 align-items-center">
        <label className="user-details-title text-capitalize mr-2">{title} :</label>
        {!editfield && <span className="user-details-value">{" " + field}</span>}
        {editfield && (
          <form className="d-flex align-content-center user-details-form" onSubmit={handleUpdate}>
            {title === "currency" ? (
              <select
                className="form-control user-details-input mr-2 mb-2"
                value={field}
                onChange={(e) => setField(e.target.value)}
              >
                <option value="CAD">CAD</option>
                <option value="CHF">CHF</option>
                <option value="EUR">EUR</option>
                <option value="GBP">GBP</option>
                <option value="INR">INR</option>
                <option value="OMR">OMR</option>
                <option value="USD">USD</option>
                <option value="VND">VND</option>
              </select>
            ) : title === "role" ? (
              <select
                className="form-control user-details-input mr-2 mb-2"
                value={field}
                onChange={(e) => setField(e.target.value)}
              >
                <option value="SUPER-ADMIN">SUPER-ADMIN</option>
                <option value="ADMIN">ADMIN</option>
              </select>
            ) : title === "status" ? (
              <select
                className="form-control user-details-input mr-2 mb-2"
                value={field}
                onChange={(e) => setField(e.target.value)}
              >
                <option value="demo">DEMO</option>
                <option value="paid">PAID</option>
                <option value="deactivate">DEACTIVATE</option>
              </select>
            ) : (
              <input
                type="text"
                value={field}
                className="user-details-input mr-2 mb-2"
                onChange={(e) => setField(e.target.value)}
              />
            )}
            <div className="d-flex align-items-center">
              <button type="submit" className="btn btn-rounded cursor-pointer user-details-btn mr-1">
                <i className="bi bi-check-circle text-success h3"></i>
              </button>
              <button type="button" className="btn btn-rounded cursor-pointer user-details-btn" onClick={() => setEditField(false)}>
                <i className="bi bi-x-circle text-danger h3"></i>
              </button>
            </div>
          </form>
        )}
        <span
          className="user-details-edit-btn p-2 text-capitalize rounded-circle mybtn"
          onClick={() => setEditField(!editfield)}
        >
          {!editfield ? (
            <button className="btn btn-rounded cursor-pointer">
              <i className="bi bi-pen h6"></i>
            </button>
          ) : null}
        </span>
      </li>
    );
  };

  return (
    <div className={"user-details"} style={{ padding: "20px" }}>
      {loggedInUser && loggedInUser.role === "SUPER-ADMIN" && (
        <Link to={".."} className="btn btn-link">
          {"< Back"}
        </Link>
      )}
      <div className="d-md-flex justify-content-between align-items-center">
        <h3>{`Name: ${user ? user.firstName + " " + user.lastName : ""}`}</h3>

        {loggedInUser && loggedInUser.role === "SUPER-ADMIN"?<button
          className={`border-only-btn ${isDeactivated ? 'text-success' : 'text-danger'}`}
          onClick={() => setShowConfirm(true)}
          style={{
            width: '200px',
            color: isDeactivated ? 'green' : 'red',
          }}
        >
          {isDeactivated ? 'Activate Account' : 'Deactivate Account'}
        </button>:<span className="status-chip status-chip-demo">{user&&user.status.toUpperCase()}</span>}

        {showConfirm && (
          <div className="dialog-overlay">
            <div className="dialog">
              <h3>Are you sure?</h3>
              <p>Are you sure you want to {isDeactivated ? 'activate' : 'deactivate'} the account?</p>
              <button onClick={handleConfirm}>Yes</button>
              <button onClick={() => setShowConfirm(false)}>No</button>
            </div>
          </div>
        )}
      </div>
      {user && (
        <div className="user-detail-section">
          <div>
            <h4 style={{ padding: "10px 0px", display: 'inline-block' }}>Personal Details</h4>
            <button
              style={{ float: 'right' }}
              onClick={() => {
                setModalOpen(true);
                setModalTitle("Reset Password");
                setModalComponent(
                  <ResetPassword closeModal={() => setModalOpen(false)} user={user}/>
                );
              }}
              className="text-primary border-only-btn"
            >
              Reset Password
            </button>
          </div>
          <div className="row">
            <UserDetails title="firstName" value={user.firstName} />
            <UserDetails title="lastName" value={user.lastName} />
            <UserDetails title="email" value={user.email} />
            <UserDetails title="phone" value={user.phone} />
            <UserDetails title="address" value={user.address} />
            <UserDetails title="pincode" value={user.pincode} />
            <UserDetails title="currency" value={user.currency} />
            <UserDetails title="paymentProviderCode" value={user.paymentProviderCode} className="user-details-label" />
            <UserDetails title="paymentProviderType" value={user.paymentProviderType} className="user-details-label" />
            {loggedInUser && loggedInUser.role === "SUPER-ADMIN" && (<UserDetails title="status" value={user.status} />)}



            <UserDetails title="taxPerc" value={user.taxPerc} />
            {loggedInUser && loggedInUser.role === "SUPER-ADMIN" && (<UserDetails title="role" value={user.role} />)}
            {loggedInUser && loggedInUser.role === "SUPER-ADMIN" && (
              <UserDetails title="screensAllowed" value={user.screensAllowed} />
            )}
          </div>
        </div>
      )}

      {user && !(user.role === "member" || MemberIdCheck(user.role)) && (
        <div className="user-detail-section">

          <div >
            <Roles modalState={modalOpen} showModal={(status) => setModalOpen(status)} showModalContent={(c) => { setModalOpen(true), setModalTitle('Add New Role'), setModalComponent(c) }} />
          </div>
        </div>
      )}

      {user && !(user.role === "member" || MemberIdCheck(user.role)) && (
        <div className="user-detail-section">

          <div>
            <Members modalState={modalOpen} showModal={(status) => setModalOpen(status)} showModalContent={(c, title) => { setModalOpen(true), setModalTitle(title), setModalComponent(c) }} />
          </div>
        </div>
      )}


      {user && !(user.role === "member" || MemberIdCheck(user.role)) && (
        <div className="user-detail-section">

          <div >
            <h4 style={{ display: "inline-block" }}>Bill Details</h4>
            {user && user.role === "SUPER-ADMIN" && (
              <button
                onClick={() => setCreateBill(true)}
                style={{ float: 'right' }}
                className="text-primary  border-only-btn"
              >
                Generate New Bill
              </button>
            )}
          </div>
          {user && (
            <div id="bill_All_Details" style={{ marginTop: "20px" }}>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th style={{ width: "30%" }}>Due Date</th>
                    <th style={{ width: "30%" }}>Status</th>
                    <th style={{ width: "20%" }}>Amount</th>
                    {user && user.role === "SUPER-ADMIN" && (
                      <th style={{ width: "10%" }}>Mark Paid</th>
                    )}
                    <th style={{ width: "10%" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {billData &&
                    billData.map((user) => (
                      <tr key={user._id}>
                        <td>{convertDateFormate(user.createdAt)}</td>
                        <td
                          style={{ textTransform: "uppercase" }}
                          className={`mt-2 badge badge-pill align-items-center ${user.payStatus === "paid"
                            ? "badge-success"
                            : "badge-warning"
                            } statusButton`}
                        >
                          {user.payStatus}
                        </td>
                        <td>
                          <Currency
                            quantity={
                              user && user.purchaseItems
                                ? user.purchaseItems && JSON.parse(user.purchaseItems)[0].price_data.unit_amount / 100
                                : 0
                            }
                            currency={
                              user && user.purchaseItems
                                ? user.purchaseItems && JSON.parse(user.purchaseItems)[0].price_data
                                  .currency
                                : "INR"
                            }
                          />
                        </td>
                        {role && role.user && role.user.role === "SUPER-ADMIN" && (
                          <td>
                            <input
                              type="checkbox"
                              className={'checkbox-common'}
                              name=""
                              id=""
                              checked={user.payStatus === "paid"}
                              onChange={() =>
                                updateSetCheckBox(
                                  user._id,
                                  user.payStatus === "paid" ? "unpaid" : "paid"
                                )
                              }
                            />
                          </td>
                        )}
                        {role && role.user && role.user.role === "SUPER-ADMIN" ? <td style={{ whiteSpace: "nowrap" }}>
                          <button
                            onClick={() => {
                              editBillData(user._id);
                            }}
                            className="btn btn-sm btn-primary mr-1"
                          >
                            Edit
                          </button>
                        </td> :
                          <td style={{ whiteSpace: "nowrap" }}>
                            <button
                              onClick={() => {
                                openPayment(user._id);
                              }}
                              className="btn btn-sm btn-primary mr-1"
                            >
                              Pay Now
                            </button>
                          </td>}
                        <td>
                          <button className="text-primary border-only-btn"
                            onClick={() => {
                              setShowPopup(true);
                              setInvoiceTitle("Invoice");
                              setInvoiceComponent(
                                <Invoice user={user} userDetails={userDetails}  />
                              );
                            }}>Invoice</button>
                        </td>
                      </tr>
                    ))}
                  {!user && (
                    <tr>
                      <td colSpan="4" className="text-center">
                        <span className="spinner-border spinner-border-lg align-center"></span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}



      {/* Opening invoice */}


      {/* Closing invoice */}


      {/* Edit Bill Start */}

      {editBill && user && !(user.role === "member" || MemberIdCheck(user.role)) && (
        <div id="bill_summary">
          <div className="Form_blur_background" onClick={() => setEditBill(false)}></div>
          <div className="modal-content">
            <h4>Billing Details</h4>

            {/* Title */}
            <div>
              <label htmlFor="title">Title For Product/Service</label>
              <input
                id="title"
                className="modal-input"
                onChange={(e) => updateBillProduct(e, "name")}
                type="text"
                defaultValue={bill ? bill.purchaseItems[0].price_data.product_data.name : ""}
              />
            </div>

            {/* Description */}
            <div>
              <label htmlFor="billText">Description For Product/Service</label>
              <textarea
                id="billText"
                className="modal-textarea"
                defaultValue={bill ? bill.purchaseItems[0].price_data.product_data.description : ""}
                onChange={(e) => updateBillProduct(e, "description")}
                placeholder="Enter User Package details here..."
              ></textarea>
            </div>

            {/* Currency and Payment Status */}
            <div>
              <label htmlFor="curr-select">Select Currency</label>
              <select
                id="curr-select"
                className="modal-select"
                onChange={(e) => updateBillPrice(e, "currency")}
              >
                <option value="CAD" selected={bill && bill.purchaseItems[0].price_data.currency === "CAD"}>CAD</option>
                <option value="EUR" selected={bill && bill.purchaseItems[0].price_data.currency === "EUR"}>EUR</option>
                <option value="INR" selected={bill && bill.purchaseItems[0].price_data.currency === "INR"}>INR</option>
                <option value="USD" selected={bill && bill.purchaseItems[0].price_data.currency === "USD"}>USD</option>
                <option value="VND" selected={bill && bill.purchaseItems[0].price_data.currency === "VND"}>VND</option>
              </select>
            </div>

            <div>
              <label htmlFor="payStatus">Pay Status</label>
              <select
                id="payStatus"
                className="modal-select"
                onChange={(e) => updateBillPayStatus(e, "payStatus")}
              >
                <option value="paid" selected={bill && bill.payStatus === "paid"}>Paid</option>
                <option value="unpaid" selected={bill && bill.payStatus === "unpaid"}>Unpaid</option>
              </select>
            </div>

            <div>
              <label htmlFor="amount">Amount to pay (In cents)</label>
              <input
                id="amount"
                className="modal-input"
                type="number"
                defaultValue={bill ? parseInt(bill.purchaseItems[0].price_data.unit_amount) : 0}
                onChange={(e) => updateBillPrice(e, "unit_amount")}
              />
            </div>

            {/* Save and Close Buttons */}
            <div className="button-container">
              <button
                onClick={() => setEditBill(false)}
                className="btn btn-danger"
              >
                Close
              </button>

              <button
                onClick={() => onBillingInfoUpdate(bill._id)}
                disabled={isSubmitting}
                className="btn btn-primary"
              >
                {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                Save
              </button>

            </div>
          </div>
        </div>
      )}

      {/* Edit Bill End */}

      {/* Create Bill Start */}
      {createBill && user && !(user.role === "member" || MemberIdCheck(user.role)) && (
        <div id="bill_summary" className="create-bill-container">
          <div
            className="create-bill-overlay"
            onClick={() => setCreateBill(false)}
          ></div>


          <div className="create-bill-box">
            <h4 className="create-bill-title">Billing Details</h4>


            <div className="form-group">
              <label htmlFor="title" className="form-label">Title For Product/Service</label>
              <input
                id="title"
                className="form-input"
                onChange={(e) => createBillProduct(e, "name")}
                type="text"
                defaultValue={
                  createBillData
                    ? createBillData.purchaseItems[0].price_data.product_data.name
                    : ""
                }
              />
            </div>

            {/* Description */}
            <div className="form-group">
              <label htmlFor="billText" className="form-label">Description For Product/Service</label>
              <textarea
                id="billText"
                className="form-textarea"
                defaultValue={
                  createBillData
                    ? createBillData.purchaseItems[0].price_data.product_data.description
                    : ""
                }
                onChange={(e) => createBillProduct(e, "description")}
                placeholder="Enter User Package details here..."
              ></textarea>
            </div>

            {/* Currency */}
            <div className="form-group">
              <label htmlFor="curr-select" className="form-label">Select Currency</label>
              <select
                id="curr-select"
                className="form-select"
                onChange={(e) => createBillPrice(e, "currency")}
              >
                <option
                  selected={createBillData && createBillData.purchaseItems[0].price_data.currency == "CAD"}
                  value="CAD"
                >
                  CAD
                </option>
                <option
                  selected={createBillData && createBillData.purchaseItems[0].price_data.currency == "EUR"}
                  value="EUR"
                >
                  EUR
                </option>
                <option
                  selected={createBillData && createBillData.purchaseItems[0].price_data.currency == "INR"}
                  value="INR"
                >
                  INR
                </option>
                <option
                  selected={createBillData && createBillData.purchaseItems[0].price_data.currency == "USD"}
                  value="USD"
                >
                  USD
                </option>
                <option
                  selected={createBillData && createBillData.purchaseItems[0].price_data.currency == "VND"}
                  value="VND"
                >
                  VND
                </option>
              </select>
            </div>

            {/* Pay Status */}
            <div className="form-group">
              <label htmlFor="pay-status" className="form-label">Pay Status</label>
              <select
                id="pay-status"
                className="form-select"
                onChange={(e) => createBillPayStatus(e, "payStatus")}
              >
                <option selected={createBillData && createBillData.payStatus == "paid"} value="paid">
                  Paid
                </option>
                <option selected={createBillData && createBillData.payStatus == "unpaid"} value="unpaid">
                  Unpaid
                </option>
              </select>
            </div>

            {/* Amount */}
            <div className="form-group">
              <label htmlFor="amount" className="form-label">Amount to pay (In cent)</label>
              <input
                id="amount"
                className="form-input"
                type="number"
                defaultValue={
                  createBillData
                    ? parseInt(createBillData.purchaseItems[0].price_data.unit_amount)
                    : 0
                }
                onChange={(e) => createBillPrice(e, "unit_amount")}
              />
            </div>

            {/* Buttons */}
            <div className="form-actions">
              <button
                onClick={() => setCreateBill(false)}
                className="btn btn-danger"
              >
                Close
              </button>
              <button
                onClick={() => onCreateBill()}
                disabled={isSubmitting}
                className="btn btn-primary"
              >
                {isSubmitting && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                Create
              </button>

            </div>
          </div>
        </div>
      )}

      {/* Create Bill End */}



      <div
        className="modal-popup"
        style={{ display: modalOpen ? "block" : "none" }}
      >
        <div className="modal">
          <article className="modal-container">
            <header className="modal-container-header">
              <h3 className="modal-container-title">{modalTitle}</h3>
              <button className="icon-button" onClick={() => setModalOpen(false)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    fill="currentColor"
                    d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
                  />
                </svg>
              </button>
            </header>
            <section className="modal-container-body rtf">{modalComponent}</section>
            <footer style={{ display: "none" }} class="modal-container-footer">
              <button class="button is-ghost">Decline</button>
              <button class="button is-primary">Accept</button>
            </footer>
          </article>
        </div>
      </div>

      <div
        className="modal-popup"
        style={{ display: showPopup ? "block" : "none" }}
      >
        <div className="modal">
          <article className="invoiceModelContainer">
            <header className="modal-container-header">
              <h3 className="modal-container-title">{invoiceTitle}</h3>
              <button className="icon-button" onClick={() => setShowPopup(false)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    fill="currentColor"
                    d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
                  />
                </svg>
              </button>
            </header>
            <section className="modal-container-body rtf">{invoiceComponent}</section>
            <footer style={{ display: "none" }} class="modal-container-footer">
              <button class="button is-ghost">Decline</button>
              <button class="button is-primary">Accept</button>
            </footer>
          </article>
        </div>
      </div>
    </div>
  );
}

export { View };
